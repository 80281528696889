import React from "react"
import RacePageOneDay2022 from "../components/racepageOneDay2022"

const Race2022 = () => {
  const startlist = {
    info: {
      providedBy: "FirstCycling.com",
      race: "La Fl\u00c3\u00a8che Wallonne F&#233;minine",
      edition: 2022,
      what: "Race data",
      updated: "2022-04-19 18:04:06",
      linkRiders: "https://firstcycling.com/rider.php?r={id}",
      linkTeams: "https://firstcycling.com/team.php?l={teamId}",
    },
    teams: {
      21872: {
        teamId: "21872",
        teamUciCode: "FDJ",
        teamName: "FDJ-Nouvelle Aquitaine-Futuroscope",
        teamNationCode: "FRA",
      },
      21877: {
        teamId: "21877",
        teamUciCode: "DSM",
        teamName: "Team DSM",
        teamNationCode: "NED",
      },
      21881: {
        teamId: "21881",
        teamUciCode: "TFS",
        teamName: "Trek-Segafredo",
        teamNationCode: "USA",
      },
      22011: {
        teamId: "22011",
        teamUciCode: "SDW",
        teamName: "Team SD Worx",
        teamNationCode: "NED",
      },
      22296: {
        teamId: "22296",
        teamUciCode: "MOV",
        teamName: "Movistar Team",
        teamNationCode: "ESP",
      },
      22809: {
        teamId: "22809",
        teamUciCode: "BEX",
        teamName: "Team BikeExchange-Jayco",
        teamNationCode: "AUS",
      },
      23616: {
        teamId: "23616",
        teamUciCode: "LSL",
        teamName: "Lotto Soudal Ladies",
        teamNationCode: "BEL",
      },
      23744: {
        teamId: "23744",
        teamUciCode: "CSR",
        teamName: "CANYON//SRAM Racing",
        teamNationCode: "GER",
      },
      24283: {
        teamId: "24283",
        teamUciCode: "PHV",
        teamName: "Parkhotel Valkenburg",
        teamNationCode: "NED",
      },
      27553: {
        teamId: "27553",
        teamUciCode: "UXT",
        teamName: "Uno-X Pro Cycling Team",
        teamNationCode: "NOR",
      },
      27874: {
        teamId: "27874",
        teamUciCode: "LIV",
        teamName: "Liv Racing Xstra",
        teamNationCode: "NED",
      },
      27895: {
        teamId: "27895",
        teamUciCode: "JVW",
        teamName: "Team Jumbo-Visma",
        teamNationCode: "NED",
      },
      27898: {
        teamId: "27898",
        teamUciCode: "ARK",
        teamName: "Ark\u00c3\u00a9a Pro Cycling Team",
        teamNationCode: "FRA",
      },
      27926: {
        teamId: "27926",
        teamUciCode: "TIB",
        teamName: "EF Education-TIBCO-SVB",
        teamNationCode: "USA",
      },
      27951: {
        teamId: "27951",
        teamUciCode: "LEW",
        teamName: "Le Col Wahoo",
        teamNationCode: "GBR",
      },
      28095: {
        teamId: "28095",
        teamUciCode: "COF",
        teamName: "Cofidis Women Team",
        teamNationCode: "FRA",
      },
      28260: {
        teamId: "28260",
        teamUciCode: "HPW",
        teamName: "Human Powered Health",
        teamNationCode: "USA",
      },
      28364: {
        teamId: "28364",
        teamUciCode: "ASC",
        teamName: "Andy Schleck-CP NVST-Immo Losch",
        teamNationCode: "LUX",
      },
      28433: {
        teamId: "28433",
        teamUciCode: "CGS",
        teamName: "Roland Cogeas Edelweiss Squad",
        teamNationCode: "SUI",
      },
      28537: {
        teamId: "28537",
        teamUciCode: "PLP",
        teamName: "Plantur-Pura",
        teamNationCode: "BEL",
      },
      28572: {
        teamId: "28572",
        teamUciCode: "BCV",
        teamName: "Bingoal Casino-Chevalmeire-Van Eyck Sport",
        teamNationCode: "BEL",
      },
      28954: {
        teamId: "28954",
        teamUciCode: "VAL",
        teamName: "Valcar-Travel & Service",
        teamNationCode: "ITA",
      },
      28991: {
        teamId: "28991",
        teamUciCode: "UAD",
        teamName: "UAE Team ADQ",
        teamNationCode: "UAE",
      },
      29009: {
        teamId: "29009",
        teamUciCode: "BPK",
        teamName: "BePink",
        teamNationCode: "ITA",
      },
    },
    riders: {
      89807: {
        id: 89807,
        startno: 102,
        firstName: "Valerie",
        lastName: "Demey",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-01-17",
        teamId: 27874,
        teamName: "Liv Racing Xstra",
        stillInTheRace: "Y",
      },
      176810: {
        id: 176810,
        startno: 162,
        firstName: "Millie",
        lastName: "Couzens",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "2003-10-29",
        teamId: 28537,
        teamName: "Plantur-Pura",
        stillInTheRace: "Y",
      },
      89405: {
        id: 89405,
        startno: 5,
        firstName: "Chantal",
        lastName: "van den Broek-Blaak",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1989-10-22",
        teamId: 22011,
        teamName: "Team SD Worx",
        stillInTheRace: "Y",
      },
      95291: {
        id: 95291,
        startno: 101,
        firstName: "Marta",
        lastName: "Jaskulska",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "2000-03-25",
        teamId: 27874,
        teamName: "Liv Racing Xstra",
        stillInTheRace: "Y",
      },
      89488: {
        id: 89488,
        startno: 103,
        firstName: "Alison",
        lastName: "Jackson",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1988-12-14",
        teamId: 27874,
        teamName: "Liv Racing Xstra",
        stillInTheRace: "Y",
      },
      96725: {
        id: 96725,
        startno: 113,
        firstName: "Hannah",
        lastName: "Ludwig",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "2000-02-15",
        teamId: 27553,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      89421: {
        id: 89421,
        startno: 81,
        firstName: "Anouska",
        lastName: "Koster",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-08-20",
        teamId: 27895,
        teamName: "Team Jumbo-Visma",
        stillInTheRace: "Y",
      },
      89533: {
        id: 89533,
        startno: 76,
        firstName: "Urska",
        lastName: "Zigart",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1996-12-04",
        teamId: 22809,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      89450: {
        id: 89450,
        startno: 73,
        firstName: "Ane",
        lastName: "Santesteban",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1990-12-12",
        teamId: 22809,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      89448: {
        id: 89448,
        startno: 15,
        firstName: "Soraya",
        lastName: "Paladin",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-05-04",
        teamId: 23744,
        teamName: "CANYON//SRAM Racing",
        stillInTheRace: "Y",
      },
      99107: {
        id: 99107,
        startno: 154,
        firstName: "Anne",
        lastName: "Van Rooijen",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2000-06-04",
        teamId: 24283,
        teamName: "Parkhotel Valkenburg",
        stillInTheRace: "Y",
      },
      89801: {
        id: 89801,
        startno: 23,
        firstName: "Leah",
        lastName: "Thomas",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1989-05-29",
        teamId: 21881,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      89430: {
        id: 89430,
        startno: 72,
        firstName: "Alexandra",
        lastName: "Manly",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1996-02-28",
        teamId: 22809,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      104035: {
        id: 104035,
        startno: 184,
        firstName: "Amandine",
        lastName: "Fouquenet",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "2001-02-19",
        teamId: 27898,
        teamName: "Ark\u00c3\u00a9a Pro Cycling Team",
        stillInTheRace: "Y",
      },
      154474: {
        id: 154474,
        startno: 143,
        firstName: "Carlotta",
        lastName: "Cipressi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2003-06-11",
        teamId: 28954,
        teamName: "Valcar-Travel & Service",
        stillInTheRace: "Y",
      },
      89950: {
        id: 89950,
        startno: 32,
        firstName: "Katrine",
        lastName: "Aalerud",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1994-12-04",
        teamId: 22296,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      93497: {
        id: 93497,
        startno: 195,
        firstName: "Elise",
        lastName: "Vander Sande",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1997-12-01",
        teamId: 23616,
        teamName: "Lotto Soudal Ladies",
        stillInTheRace: "Y",
      },
      96720: {
        id: 96720,
        startno: 66,
        firstName: "Jade",
        lastName: "Wiel",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "2000-04-02",
        teamId: 21872,
        teamName: "FDJ-Nouvelle Aquitaine-Futuroscope",
        stillInTheRace: "Y",
      },
      97831: {
        id: 97831,
        startno: 142,
        firstName: "Anastasia",
        lastName: "Carbonari",
        nationCode: "LAT",
        nationName: "Latvia",
        birthDate: "1999-09-11",
        teamId: 28954,
        teamName: "Valcar-Travel & Service",
        stillInTheRace: "Y",
      },
      113731: {
        id: 113731,
        startno: 122,
        firstName: "Henrietta",
        lastName: "Christie",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "2002-01-23",
        teamId: 28260,
        teamName: "Human Powered Health",
        stillInTheRace: "Y",
      },
      89824: {
        id: 89824,
        startno: 53,
        firstName: "Leah",
        lastName: "Kirchmann",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1990-06-30",
        teamId: 21877,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      154301: {
        id: 154301,
        startno: 174,
        firstName: "Alice",
        lastName: "Towers",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "2002-10-12",
        teamId: 27951,
        teamName: "Le Col Wahoo",
        stillInTheRace: "Y",
      },
      114476: {
        id: 114476,
        startno: 24,
        firstName: "Shirin",
        lastName: "van Anrooij",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2002-02-05",
        teamId: 21881,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      138754: {
        id: 138754,
        startno: 132,
        firstName: "Tamara",
        lastName: "Dronova",
        nationCode: "RUS",
        nationName: "Russia",
        birthDate: "1993-08-13",
        teamId: 28433,
        teamName: "Roland Cogeas Edelweiss Squad",
        stillInTheRace: "Y",
      },
      125389: {
        id: 125389,
        startno: 191,
        firstName: "Kristyna",
        lastName: "Burlova",
        nationCode: "CZE",
        nationName: "Czech Republic",
        birthDate: "2002-03-25",
        teamId: 23616,
        teamName: "Lotto Soudal Ladies",
        stillInTheRace: "Y",
      },
      96710: {
        id: 96710,
        startno: 234,
        firstName: "Friederike",
        lastName: "Stern",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "2001-02-15",
        teamId: 28364,
        teamName: "Andy Schleck-CP NVST-Immo Losch",
        stillInTheRace: "Y",
      },
      90014: {
        id: 90014,
        startno: 121,
        firstName: "Nina",
        lastName: "Buijsman",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1997-11-16",
        teamId: 28260,
        teamName: "Human Powered Health",
        stillInTheRace: "Y",
      },
      159727: {
        id: 159727,
        startno: 135,
        firstName: "Petra",
        lastName: "Stiasny",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "2001-09-10",
        teamId: 28433,
        teamName: "Roland Cogeas Edelweiss Squad",
        stillInTheRace: "Y",
      },
      89921: {
        id: 89921,
        startno: 64,
        firstName: "Eug\u00c3\u00a9nie",
        lastName: "Duval",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1993-05-03",
        teamId: 21872,
        teamName: "FDJ-Nouvelle Aquitaine-Futuroscope",
        stillInTheRace: "Y",
      },
      89489: {
        id: 89489,
        startno: 44,
        firstName: "Erica",
        lastName: "Magnaldi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1992-08-24",
        teamId: 28991,
        teamName: "UAE Team ADQ",
        stillInTheRace: "Y",
      },
      154489: {
        id: 154489,
        startno: 193,
        firstName: "Mijntje",
        lastName: "Geurts",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2003-10-25",
        teamId: 23616,
        teamName: "Lotto Soudal Ladies",
        stillInTheRace: "Y",
      },
      89843: {
        id: 89843,
        startno: 94,
        firstName: "Lauren",
        lastName: "Stephens",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1986-12-27",
        teamId: 27926,
        teamName: "EF Education-TIBCO-SVB",
        stillInTheRace: "Y",
      },
      164720: {
        id: 164720,
        startno: 164,
        firstName: "Justine",
        lastName: "Ghekiere",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1996-05-14",
        teamId: 28537,
        teamName: "Plantur-Pura",
        stillInTheRace: "Y",
      },
      96832: {
        id: 96832,
        startno: 202,
        firstName: "C\u00c3\u00a9drine",
        lastName: "Kerbaol",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "2001-05-15",
        teamId: 28095,
        teamName: "Cofidis Women Team",
        stillInTheRace: "Y",
      },
      96875: {
        id: 96875,
        startno: 226,
        firstName: "Matilde",
        lastName: "Vitillo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2001-03-08",
        teamId: 29009,
        teamName: "BePink",
        stillInTheRace: "Y",
      },
      93003: {
        id: 93003,
        startno: 114,
        firstName: "Mie Bj\u00c3\u00b8rndal",
        lastName: "Ottestad",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1997-07-17",
        teamId: 27553,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      96804: {
        id: 96804,
        startno: 214,
        firstName: "Lotte",
        lastName: "Popelier",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "2001-05-21",
        teamId: 28572,
        teamName: "Bingoal Casino-Chevalmeire-Van Eyck Sport",
        stillInTheRace: "Y",
      },
      90984: {
        id: 90984,
        startno: 112,
        firstName: "Joscelin",
        lastName: "Lowden",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1987-10-03",
        teamId: 27553,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      93562: {
        id: 93562,
        startno: 1,
        firstName: "Demi",
        lastName: "Vollering",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-11-15",
        teamId: 22011,
        teamName: "Team SD Worx",
        stillInTheRace: "Y",
      },
      89419: {
        id: 89419,
        startno: 11,
        firstName: "Katarzyna",
        lastName: "Niewiadoma",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1994-09-29",
        teamId: 23744,
        teamName: "CANYON//SRAM Racing",
        stillInTheRace: "Y",
      },
      89558: {
        id: 89558,
        startno: 206,
        firstName: "Gabrielle",
        lastName: "Pilote-Fortin",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1993-04-26",
        teamId: 28095,
        teamName: "Cofidis Women Team",
        stillInTheRace: "Y",
      },
      89839: {
        id: 89839,
        startno: 92,
        firstName: "Kathrin",
        lastName: "Hammes",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1989-01-09",
        teamId: 27926,
        teamName: "EF Education-TIBCO-SVB",
        stillInTheRace: "Y",
      },
      154477: {
        id: 154477,
        startno: 56,
        firstName: "Elise",
        lastName: "Uijen",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2003-06-23",
        teamId: 21877,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      98823: {
        id: 98823,
        startno: 74,
        firstName: "Chelsie Wei Shi",
        lastName: "Tan",
        nationCode: "SIN",
        nationName: "Singapore",
        birthDate: "1990-01-17",
        teamId: 22809,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      90596: {
        id: 90596,
        startno: 41,
        firstName: "Mavi",
        lastName: "Garcia",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1984-01-02",
        teamId: 28991,
        teamName: "UAE Team ADQ",
        stillInTheRace: "Y",
      },
      89424: {
        id: 89424,
        startno: 133,
        firstName: "Rotem",
        lastName: "Gafinovitz",
        nationCode: "ISR",
        nationName: "Israel",
        birthDate: "1992-06-09",
        teamId: 28433,
        teamName: "Roland Cogeas Edelweiss Squad",
        stillInTheRace: "Y",
      },
      96745: {
        id: 96745,
        startno: 153,
        firstName: "Rosalie",
        lastName: "Van Der Wolf",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2001-03-11",
        teamId: 24283,
        teamName: "Parkhotel Valkenburg",
        stillInTheRace: "Y",
      },
      98961: {
        id: 98961,
        startno: 213,
        firstName: "Olha",
        lastName: "Kulynych",
        nationCode: "UKR",
        nationName: "Ukraine",
        birthDate: "2000-02-01",
        teamId: 28572,
        teamName: "Bingoal Casino-Chevalmeire-Van Eyck Sport",
        stillInTheRace: "Y",
      },
      89433: {
        id: 89433,
        startno: 71,
        firstName: "Amanda",
        lastName: "Spratt",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1987-09-17",
        teamId: 22809,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      89763: {
        id: 89763,
        startno: 182,
        firstName: "Pauline",
        lastName: "Allin",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1995-05-02",
        teamId: 27898,
        teamName: "Ark\u00c3\u00a9a Pro Cycling Team",
        stillInTheRace: "Y",
      },
      103237: {
        id: 103237,
        startno: 124,
        firstName: "Barbara",
        lastName: "Malcotti",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2000-02-19",
        teamId: 28260,
        teamName: "Human Powered Health",
        stillInTheRace: "Y",
      },
      89422: {
        id: 89422,
        startno: 104,
        firstName: "Jeanne",
        lastName: "Korevaar",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-09-24",
        teamId: 27874,
        teamName: "Liv Racing Xstra",
        stillInTheRace: "Y",
      },
      96771: {
        id: 96771,
        startno: 194,
        firstName: "Esmee",
        lastName: "Gielkens",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "2001-09-12",
        teamId: 23616,
        teamName: "Lotto Soudal Ladies",
        stillInTheRace: "Y",
      },
      108734: {
        id: 108734,
        startno: 225,
        firstName: "Jade",
        lastName: "T\u00c3\u00a9olis",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "2002-01-12",
        teamId: 29009,
        teamName: "BePink",
        stillInTheRace: "Y",
      },
      57567: {
        id: 57567,
        startno: 125,
        firstName: "Eri",
        lastName: "Yonamine",
        nationCode: "JPN",
        nationName: "Japan",
        birthDate: "1991-04-25",
        teamId: 28260,
        teamName: "Human Powered Health",
        stillInTheRace: "Y",
      },
      89435: {
        id: 89435,
        startno: 75,
        firstName: "Georgia",
        lastName: "Williams",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1993-08-25",
        teamId: 22809,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      89534: {
        id: 89534,
        startno: 12,
        firstName: "Alena",
        lastName: "Amialiusik",
        nationCode: "BLR",
        nationName: "Belarus",
        birthDate: "1989-06-02",
        teamId: 23744,
        teamName: "CANYON//SRAM Racing",
        stillInTheRace: "Y",
      },
      89641: {
        id: 89641,
        startno: 171,
        firstName: "Elizabeth",
        lastName: "Holden",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1997-09-12",
        teamId: 27951,
        teamName: "Le Col Wahoo",
        stillInTheRace: "Y",
      },
      89823: {
        id: 89823,
        startno: 22,
        firstName: "Lucinda",
        lastName: "Brand",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1989-07-02",
        teamId: 21881,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      168313: {
        id: 168313,
        startno: 43,
        firstName: "Alena",
        lastName: "Ivanchenko",
        nationCode: "RUS",
        nationName: "Russia",
        birthDate: "2003-11-16",
        teamId: 28991,
        teamName: "UAE Team ADQ",
        stillInTheRace: "Y",
      },
      89480: {
        id: 89480,
        startno: 36,
        firstName: "Arlenis",
        lastName: "Sierra",
        nationCode: "CUB",
        nationName: "Cuba",
        birthDate: "1992-12-07",
        teamId: 22296,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      89552: {
        id: 89552,
        startno: 203,
        firstName: "Clara",
        lastName: "Koppenburg",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1995-08-03",
        teamId: 28095,
        teamName: "Cofidis Women Team",
        stillInTheRace: "Y",
      },
      90020: {
        id: 90020,
        startno: 83,
        firstName: "Aafke",
        lastName: "Soet",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1997-11-23",
        teamId: 27895,
        teamName: "Team Jumbo-Visma",
        stillInTheRace: "Y",
      },
      92095: {
        id: 92095,
        startno: 13,
        firstName: "Elise",
        lastName: "Chabbey",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1993-04-24",
        teamId: 23744,
        teamName: "CANYON//SRAM Racing",
        stillInTheRace: "Y",
      },
      96716: {
        id: 96716,
        startno: 52,
        firstName: "L\u00c3\u00a9a",
        lastName: "Curinier",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "2001-04-21",
        teamId: 21877,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      89470: {
        id: 89470,
        startno: 42,
        firstName: "Sofia",
        lastName: "Bertizzolo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1997-08-21",
        teamId: 28991,
        teamName: "UAE Team ADQ",
        stillInTheRace: "Y",
      },
      93682: {
        id: 93682,
        startno: 166,
        firstName: "Inge",
        lastName: "Van der Heijden",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1999-08-12",
        teamId: 28537,
        teamName: "Plantur-Pura",
        stillInTheRace: "Y",
      },
      89596: {
        id: 89596,
        startno: 91,
        firstName: "Krista",
        lastName: "Doebel-Hickok",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1989-04-28",
        teamId: 27926,
        teamName: "EF Education-TIBCO-SVB",
        stillInTheRace: "Y",
      },
      99155: {
        id: 99155,
        startno: 163,
        firstName: "Kiona",
        lastName: "Crabbe",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "2001-02-18",
        teamId: 28537,
        teamName: "Plantur-Pura",
        stillInTheRace: "Y",
      },
      93081: {
        id: 93081,
        startno: 186,
        firstName: "Ana\u00c3\u00afs",
        lastName: "Morichon",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1999-10-06",
        teamId: 27898,
        teamName: "Ark\u00c3\u00a9a Pro Cycling Team",
        stillInTheRace: "Y",
      },
      96819: {
        id: 96819,
        startno: 221,
        firstName: "Silvia",
        lastName: "Zanardi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2000-03-03",
        teamId: 29009,
        teamName: "BePink",
        stillInTheRace: "Y",
      },
      114768: {
        id: 114768,
        startno: 115,
        firstName: "Anne Dorthe",
        lastName: "Ysland",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "2002-04-09",
        teamId: 27553,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      156243: {
        id: 156243,
        startno: 212,
        firstName: "Julie",
        lastName: "Hendrickx",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "2003-08-08",
        teamId: 28572,
        teamName: "Bingoal Casino-Chevalmeire-Van Eyck Sport",
        stillInTheRace: "Y",
      },
      89535: {
        id: 89535,
        startno: 111,
        firstName: "Hannah",
        lastName: "Barnes",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1993-05-04",
        teamId: 27553,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      92696: {
        id: 92696,
        startno: 233,
        firstName: "Claire",
        lastName: "Faber",
        nationCode: "LUX",
        nationName: "Luxembourg",
        birthDate: "1998-06-21",
        teamId: 28364,
        teamName: "Andy Schleck-CP NVST-Immo Losch",
        stillInTheRace: "Y",
      },
      112048: {
        id: 112048,
        startno: 232,
        firstName: "Georgia",
        lastName: "Danford",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1999-04-03",
        teamId: 28364,
        teamName: "Andy Schleck-CP NVST-Immo Losch",
        stillInTheRace: "Y",
      },
      97208: {
        id: 97208,
        startno: 141,
        firstName: "Olivia",
        lastName: "Baril",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1997-10-10",
        teamId: 28954,
        teamName: "Valcar-Travel & Service",
        stillInTheRace: "Y",
      },
      89826: {
        id: 89826,
        startno: 54,
        firstName: "Liane",
        lastName: "Lippert",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1998-01-13",
        teamId: 21877,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      113719: {
        id: 113719,
        startno: 55,
        firstName: "Esmee",
        lastName: "Peperkamp",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1997-07-23",
        teamId: 21877,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      89525: {
        id: 89525,
        startno: 33,
        firstName: "Jelena",
        lastName: "Eric",
        nationCode: "SBA",
        nationName: "Serbia",
        birthDate: "1996-01-15",
        teamId: 22296,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      91078: {
        id: 91078,
        startno: 34,
        firstName: "Sara",
        lastName: "Mart\u00c3\u00adn",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1999-03-22",
        teamId: 22296,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      98967: {
        id: 98967,
        startno: 235,
        firstName: "Zsofia",
        lastName: "Szabo",
        nationCode: "HUN",
        nationName: "Hungary",
        birthDate: "1997-01-03",
        teamId: 28364,
        teamName: "Andy Schleck-CP NVST-Immo Losch",
        stillInTheRace: "Y",
      },
      96719: {
        id: 96719,
        startno: 152,
        firstName: "Pien",
        lastName: "Limpens",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2001-02-24",
        teamId: 24283,
        teamName: "Parkhotel Valkenburg",
        stillInTheRace: "Y",
      },
      102007: {
        id: 102007,
        startno: 183,
        firstName: "Yuliia",
        lastName: "Biriukova",
        nationCode: "UKR",
        nationName: "Ukraine",
        birthDate: "1998-01-24",
        teamId: 27898,
        teamName: "Ark\u00c3\u00a9a Pro Cycling Team",
        stillInTheRace: "Y",
      },
      91959: {
        id: 91959,
        startno: 63,
        firstName: "Brodie",
        lastName: "Chapman",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1991-04-09",
        teamId: 21872,
        teamName: "FDJ-Nouvelle Aquitaine-Futuroscope",
        stillInTheRace: "Y",
      },
      98950: {
        id: 98950,
        startno: 181,
        firstName: "Morgane",
        lastName: "Coston",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1990-12-28",
        teamId: 27898,
        teamName: "Ark\u00c3\u00a9a Pro Cycling Team",
        stillInTheRace: "Y",
      },
      89496: {
        id: 89496,
        startno: 105,
        firstName: "Katia",
        lastName: "Ragusa",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1997-05-19",
        teamId: 27874,
        teamName: "Liv Racing Xstra",
        stillInTheRace: "Y",
      },
      89832: {
        id: 89832,
        startno: 25,
        firstName: "Ellen",
        lastName: "Van Dijk",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1987-02-11",
        teamId: 21881,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      126302: {
        id: 126302,
        startno: 93,
        firstName: "Clara",
        lastName: "Honsinger",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1997-06-05",
        teamId: 27926,
        teamName: "EF Education-TIBCO-SVB",
        stillInTheRace: "Y",
      },
      89966: {
        id: 89966,
        startno: 192,
        firstName: "Mieke",
        lastName: "Docx",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1996-06-08",
        teamId: 23616,
        teamName: "Lotto Soudal Ladies",
        stillInTheRace: "Y",
      },
      167331: {
        id: 167331,
        startno: 205,
        firstName: "Olivia",
        lastName: "Onesti",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "2003-12-06",
        teamId: 28095,
        teamName: "Cofidis Women Team",
        stillInTheRace: "Y",
      },
      114522: {
        id: 114522,
        startno: 224,
        firstName: "Prisca",
        lastName: "Savi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2002-03-21",
        teamId: 29009,
        teamName: "BePink",
        stillInTheRace: "Y",
      },
      89819: {
        id: 89819,
        startno: 14,
        firstName: "Mikayla",
        lastName: "Harvey",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1998-09-07",
        teamId: 23744,
        teamName: "CANYON//SRAM Racing",
        stillInTheRace: "Y",
      },
      90686: {
        id: 90686,
        startno: 175,
        firstName: "Jesse",
        lastName: "Vandenbulcke",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1996-01-17",
        teamId: 27951,
        teamName: "Le Col Wahoo",
        stillInTheRace: "Y",
      },
      89638: {
        id: 89638,
        startno: 172,
        firstName: "Anna",
        lastName: "Christian",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1995-08-06",
        teamId: 27951,
        teamName: "Le Col Wahoo",
        stillInTheRace: "Y",
      },
      90019: {
        id: 90019,
        startno: 16,
        firstName: "Pauliena",
        lastName: "Rooijakkers",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-05-12",
        teamId: 23744,
        teamName: "CANYON//SRAM Racing",
        stillInTheRace: "Y",
      },
      89555: {
        id: 89555,
        startno: 3,
        firstName: "Ashleigh",
        lastName: "Moolman-Pasio",
        nationCode: "RSA",
        nationName: "South Africa",
        birthDate: "1985-12-09",
        teamId: 22011,
        teamName: "Team SD Worx",
        stillInTheRace: "Y",
      },
      91260: {
        id: 91260,
        startno: 35,
        firstName: "Paula",
        lastName: "Patino",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1997-03-29",
        teamId: 22296,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      114479: {
        id: 114479,
        startno: 131,
        firstName: "Hannah",
        lastName: "Buch",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "2002-09-11",
        teamId: 28433,
        teamName: "Roland Cogeas Edelweiss Squad",
        stillInTheRace: "Y",
      },
      176891: {
        id: 176891,
        startno: 82,
        firstName: "Amber",
        lastName: "Kraak",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1994-07-29",
        teamId: 27895,
        teamName: "Team Jumbo-Visma",
        stillInTheRace: "Y",
      },
      89528: {
        id: 89528,
        startno: 46,
        firstName: "Ursa",
        lastName: "Pintar",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1985-10-03",
        teamId: 28991,
        teamName: "UAE Team ADQ",
        stillInTheRace: "Y",
      },
      89825: {
        id: 89825,
        startno: 51,
        firstName: "Juliette",
        lastName: "Labous",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1998-11-04",
        teamId: 21877,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      89745: {
        id: 89745,
        startno: 21,
        firstName: "Elisa",
        lastName: "Longo Borghini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1991-12-10",
        teamId: 21881,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      89423: {
        id: 89423,
        startno: 161,
        firstName: "Yara",
        lastName: "Kastelijn",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1997-08-09",
        teamId: 28537,
        teamName: "Plantur-Pura",
        stillInTheRace: "Y",
      },
      89711: {
        id: 89711,
        startno: 26,
        firstName: "Tayler",
        lastName: "Wiles",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1989-07-19",
        teamId: 21881,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      89431: {
        id: 89431,
        startno: 201,
        firstName: "Rachel",
        lastName: "Neylan",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1982-03-09",
        teamId: 28095,
        teamName: "Cofidis Women Team",
        stillInTheRace: "Y",
      },
      100574: {
        id: 100574,
        startno: 185,
        firstName: "Typhaine",
        lastName: "Laurance",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1998-08-28",
        teamId: 27898,
        teamName: "Ark\u00c3\u00a9a Pro Cycling Team",
        stillInTheRace: "Y",
      },
      96864: {
        id: 96864,
        startno: 144,
        firstName: "Federica",
        lastName: "Piergiovanni",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2001-07-30",
        teamId: 28954,
        teamName: "Valcar-Travel & Service",
        stillInTheRace: "Y",
      },
      92142: {
        id: 92142,
        startno: 62,
        firstName: "Stine",
        lastName: "Borgli",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1990-07-04",
        teamId: 21872,
        teamName: "FDJ-Nouvelle Aquitaine-Futuroscope",
        stillInTheRace: "Y",
      },
      176998: {
        id: 176998,
        startno: 211,
        firstName: "Lotte",
        lastName: "Claes",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1993-05-05",
        teamId: 28572,
        teamName: "Bingoal Casino-Chevalmeire-Van Eyck Sport",
        stillInTheRace: "Y",
      },
      125733: {
        id: 125733,
        startno: 4,
        firstName: "Anna",
        lastName: "Shackley",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "2001-05-17",
        teamId: 22011,
        teamName: "Team SD Worx",
        stillInTheRace: "Y",
      },
      96795: {
        id: 96795,
        startno: 231,
        firstName: "Nina",
        lastName: "Berton",
        nationCode: "LUX",
        nationName: "Luxembourg",
        birthDate: "2001-08-03",
        teamId: 28364,
        teamName: "Andy Schleck-CP NVST-Immo Losch",
        stillInTheRace: "Y",
      },
      96032: {
        id: 96032,
        startno: 145,
        firstName: "Elizabeth",
        lastName: "Stannard",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1997-05-27",
        teamId: 28954,
        teamName: "Valcar-Travel & Service",
        stillInTheRace: "Y",
      },
      106424: {
        id: 106424,
        startno: 95,
        firstName: "Magdeleine",
        lastName: "Vallieres Mill",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "2001-08-10",
        teamId: 27926,
        teamName: "EF Education-TIBCO-SVB",
        stillInTheRace: "Y",
      },
      176809: {
        id: 176809,
        startno: 173,
        firstName: "Flora",
        lastName: "Perkins",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "2003-09-16",
        teamId: 27951,
        teamName: "Le Col Wahoo",
        stillInTheRace: "Y",
      },
      89434: {
        id: 89434,
        startno: 31,
        firstName: "Annemiek",
        lastName: "van Vleuten",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1982-10-08",
        teamId: 22296,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      92096: {
        id: 92096,
        startno: 45,
        firstName: "Maria",
        lastName: "Novolodskaya",
        nationCode: "RUS",
        nationName: "Russia",
        birthDate: "1999-07-28",
        teamId: 28991,
        teamName: "UAE Team ADQ",
        stillInTheRace: "Y",
      },
      96748: {
        id: 96748,
        startno: 223,
        firstName: "Marketa",
        lastName: "Hajkova",
        nationCode: "CZE",
        nationName: "Czech Republic",
        birthDate: "2000-03-14",
        teamId: 29009,
        teamName: "BePink",
        stillInTheRace: "Y",
      },
      107834: {
        id: 107834,
        startno: 6,
        firstName: "Blanka",
        lastName: "Vas",
        nationCode: "HUN",
        nationName: "Hungary",
        birthDate: "2001-09-03",
        teamId: 22011,
        teamName: "Team SD Worx",
        stillInTheRace: "Y",
      },
      96741: {
        id: 96741,
        startno: 151,
        firstName: "Femke",
        lastName: "Gerritse",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2001-05-14",
        teamId: 24283,
        teamName: "Parkhotel Valkenburg",
        stillInTheRace: "Y",
      },
      186806: {
        id: 186806,
        startno: 134,
        firstName: "L\u00c3\u00a9a",
        lastName: "Stern",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "2001-09-25",
        teamId: 28433,
        teamName: "Roland Cogeas Edelweiss Squad",
        stillInTheRace: "Y",
      },
      90106: {
        id: 90106,
        startno: 65,
        firstName: "Evita",
        lastName: "Muzic",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1999-05-26",
        teamId: 21872,
        teamName: "FDJ-Nouvelle Aquitaine-Futuroscope",
        stillInTheRace: "Y",
      },
      89716: {
        id: 89716,
        startno: 61,
        firstName: "Marta",
        lastName: "Cavalli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-03-18",
        teamId: 21872,
        teamName: "FDJ-Nouvelle Aquitaine-Futuroscope",
        stillInTheRace: "Y",
      },
      93392: {
        id: 93392,
        startno: 196,
        firstName: "Kylie",
        lastName: "Waterreus",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1998-03-22",
        teamId: 23616,
        teamName: "Lotto Soudal Ladies",
        stillInTheRace: "Y",
      },
      96724: {
        id: 96724,
        startno: 123,
        firstName: "Katie",
        lastName: "Clouse",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "2001-08-04",
        teamId: 28260,
        teamName: "Human Powered Health",
        stillInTheRace: "Y",
      },
      89886: {
        id: 89886,
        startno: 165,
        firstName: "Julie",
        lastName: "Van De Velde",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1993-06-02",
        teamId: 28537,
        teamName: "Plantur-Pura",
        stillInTheRace: "Y",
      },
      100582: {
        id: 100582,
        startno: 204,
        firstName: "Sandra",
        lastName: "Levenez",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1979-07-05",
        teamId: 28095,
        teamName: "Cofidis Women Team",
        stillInTheRace: "Y",
      },
      157541: {
        id: 157541,
        startno: 222,
        firstName: "Valentina",
        lastName: "Basilico",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2003-04-17",
        teamId: 29009,
        teamName: "BePink",
        stillInTheRace: "Y",
      },
      92102: {
        id: 92102,
        startno: 2,
        firstName: "Niamh",
        lastName: "Fisher-Black",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "2000-08-12",
        teamId: 22011,
        teamName: "Team SD Worx",
        stillInTheRace: "Y",
      },
    },
  }

  const race = "Fleche Wallone"
  const raceID = 9051

  return (
    <RacePageOneDay2022
      title={`${race} 2022`}
      startlistLocal={startlist}
      race={race}
      raceID={raceID}
    />
  )
}

export default Race2022
